import React from 'react';

function Overview() {
  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-3xl font-semibold text-gray-800 mb-4">Panoramica Attività</h2>
      <p>Attività Totali: 20</p>
      <p>Appuntamenti Completati: 15</p>
      <p>Attrezzature Manutenzione: 3</p>
    </div>
  );
}

export default Overview;
