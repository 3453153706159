import React, { useState } from 'react';
import ClientSearch from '../components/ClientSearch';

function Portfolio() {
  const [selectedClient, setSelectedClient] = useState(null);

  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-3xl font-semibold text-gray-800 mb-4">Portfolio</h2>

      {/* Ricerca Cliente */}
      <ClientSearch onSelect={setSelectedClient} />

      {selectedClient && (
        <div className="mt-4">
          <h3 className="text-xl font-semibold">Cliente selezionato:</h3>
          <p>{selectedClient.name}</p>
        </div>
      )}

      {/* Altri componenti per gestione delle gallerie */}
    </div>
  );
}

export default Portfolio;
