import React from 'react';
import StudioProfile from './Settings/StudioProfile';

function Settings() {
  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md">
      <StudioProfile />
    </div>
  );
}

export default Settings;
