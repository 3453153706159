// src/components/Clients/ClientList.js
import React, { useContext, useState, useEffect } from 'react';
import { ClientContext } from '../../context/ClientContext';
import { useNavigate } from 'react-router-dom';
import ClientSearch from '../ClientSearch';

function ClientList() {
  const { clients, addClient } = useContext(ClientContext);
  const [filteredClients, setFilteredClients] = useState(clients);
  const [query, setQuery] = useState('');
  const [newClientName, setNewClientName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (query === '') {
      setFilteredClients(clients);
    } else {
      setFilteredClients(
        clients.filter((client) =>
          client.name.toLowerCase().includes(query.toLowerCase())
        )
      );
    }
  }, [query, clients]);

  const handleAddClient = (e) => {
    e.preventDefault();
    if (newClientName) {
      addClient(newClientName);
      setNewClientName('');
    }
  };

  const handleClientClick = (id) => {
    navigate(`/clienti/${id}`);
  };

  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-3xl font-semibold text-gray-800 mb-4">Gestione Clienti</h2>
      <ClientSearch setQuery={setQuery} />
      <form onSubmit={handleAddClient} className="mb-4">
        <input
          type="text"
          value={newClientName}
          onChange={(e) => setNewClientName(e.target.value)}
          placeholder="Nome cliente"
          className="p-2 border border-gray-300 rounded mr-2"
          required
        />
        <button
          type="submit"
          className="px-4 py-2 bg-blue-600 text-white rounded shadow"
        >
          Aggiungi Cliente
        </button>
      </form>

      {filteredClients.length === 0 ? (
        <p>Non sono stati trovati clienti.</p>
      ) : (
        <ul className="list-disc pl-5">
          {filteredClients.map((client) => (
            <li key={client.id} className="mb-2">
              <button
                onClick={() => handleClientClick(client.id)}
                className="text-blue-500 hover:underline"
              >
                {client.name}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default ClientList;
