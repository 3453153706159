import React, { createContext, useState } from 'react';

export const DashboardContext = createContext();

export const DashboardProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [tasks, setTasks] = useState([]);

  const addNotification = (notification) => {
    setNotifications((prevNotifications) => [
      ...prevNotifications,
      { ...notification, id: prevNotifications.length + 1, date: new Date().toLocaleDateString() }
    ]);
  };

  const addTask = (task) => {
    setTasks((prevTasks) => [
      ...prevTasks,
      { ...task, id: prevTasks.length + 1, completed: false }
    ]);
  };

  const toggleTaskCompletion = (taskId) => {
    setTasks((prevTasks) =>
      prevTasks.map((task) =>
        task.id === taskId ? { ...task, completed: !task.completed } : task
      )
    );
  };

  return (
    <DashboardContext.Provider value={{ notifications, addNotification, tasks, addTask, toggleTaskCompletion }}>
      {children}
    </DashboardContext.Provider>
  );
};
