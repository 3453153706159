// src/components/Appointments.js
import React, { useState, useEffect, useContext } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { AppointmentContext } from '../context/AppointmentContext';
import { ClientContext } from '../context/ClientContext';
import { PackageContext } from '../context/PackageContext';

const localizer = momentLocalizer(moment);

function Appointments() {
  const { appointments, addAppointment, updateAppointment } = useContext(AppointmentContext);
  const { clients } = useContext(ClientContext);
  const { packages } = useContext(PackageContext);

  const [selectedDate, setSelectedDate] = useState(null);
  const [description, setDescription] = useState('');
  const [selectedClient, setSelectedClient] = useState('');
  const [selectedPackage, setSelectedPackage] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [appointmentId, setAppointmentId] = useState(null);

  useEffect(() => {
    if (isEditing && appointmentId) {
      const appointment = appointments.find(app => app.id === appointmentId);
      if (appointment) {
        setDescription(appointment.description);
        setSelectedClient(appointment.clientId);
        setSelectedPackage(appointment.packageId);
        setSelectedDate(new Date(appointment.date));
      }
    } else {
      resetForm();
    }
  }, [isEditing, appointmentId, appointments]);

  const handleSelectSlot = ({ start }) => {
    setSelectedDate(start);
    resetForm();
    setIsEditing(false);
  };

  const handleSelectEvent = (event) => {
    setIsEditing(true);
    setAppointmentId(event.id);
  };

  const resetForm = () => {
    setDescription('');
    setSelectedClient('');
    setSelectedPackage('');
    setAppointmentId(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (description && selectedClient && selectedPackage && selectedDate) {
      const appointmentData = {
        clientId: parseInt(selectedClient),
        description,
        date: selectedDate.toISOString(),
        packageId: selectedPackage,
      };

      if (isEditing && appointmentId) {
        updateAppointment(appointmentId, appointmentData);
      } else {
        addAppointment(appointmentData);
      }
      resetForm();
      setIsEditing(false);
    }
  };

  const events = appointments.map(app => {
    const client = clients.find(client => client.id === app.clientId) || {};
    const packageDetails = packages.find(pkg => pkg.id === app.packageId) || {};
    return {
      id: app.id,
      title: `${client.name || 'Cliente'} - ${packageDetails.name || 'Pacchetto'}`,
      start: new Date(app.date),
      end: new Date(app.date),
      client,
      packageDetails,
    };
  });

  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-3xl font-semibold text-gray-800 mb-4">Gestione Appuntamenti</h2>

      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        selectable
        onSelectSlot={handleSelectSlot}
        onSelectEvent={handleSelectEvent}
      />

      <form onSubmit={handleSubmit} className="mt-4">
        <h3 className="text-xl font-semibold text-gray-700 mb-2">{isEditing ? 'Modifica Appuntamento' : 'Aggiungi Appuntamento'}</h3>

        <div className="mb-2">
          <label className="block text-gray-700">Cliente:</label>
          <select
            value={selectedClient}
            onChange={(e) => setSelectedClient(e.target.value)}
            className="p-2 border border-gray-300 rounded"
            required
          >
            <option value="">Seleziona Cliente</option>
            {clients.map(client => (
              <option key={client.id} value={client.id}>{client.name}</option>
            ))}
          </select>
        </div>

        <div className="mb-2">
          <label className="block text-gray-700">Pacchetto:</label>
          <select
            value={selectedPackage}
            onChange={(e) => setSelectedPackage(e.target.value)}
            className="p-2 border border-gray-300 rounded"
            required
          >
            <option value="">Seleziona Pacchetto</option>
            {packages.map(pkg => (
              <option key={pkg.id} value={pkg.id}>{pkg.name} - {pkg.type} (€{pkg.price})</option>
            ))}
          </select>
        </div>

        <div className="mb-2">
          <label className="block text-gray-700">Data:</label>
          <input
            type="text"
            value={selectedDate ? selectedDate.toLocaleDateString() : ''}
            readOnly
            className="p-2 border border-gray-300 rounded"
          />
        </div>

        <div className="mb-2">
          <label className="block text-gray-700">Descrizione:</label>
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Descrizione appuntamento"
            className="p-2 border border-gray-300 rounded w-full"
            required
          />
        </div>

        <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded">
          {isEditing ? 'Aggiorna Appuntamento' : 'Aggiungi Appuntamento'}
        </button>
      </form>
    </div>
  );
}

export default Appointments;
