import React, { useContext, useState } from 'react';
import { FinanceContext } from '../../context/FinanceContext';

function Expenses() {
  const { expenses, addExpense } = useContext(FinanceContext);
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState('');

  const handleAddExpense = (e) => {
    e.preventDefault();
    addExpense({ description, amount });
    setDescription('');
    setAmount('');
  };

  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-3xl font-semibold text-gray-800 mb-4">Tracciamento Spese</h2>

      <form onSubmit={handleAddExpense} className="mb-4">
        <div className="mb-4">
          <label className="block text-gray-700">Descrizione</label>
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Importo</label>
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <button
          type="submit"
          className="px-4 py-2 bg-red-600 text-white rounded shadow"
        >
          Aggiungi Spesa
        </button>
      </form>

      <h3 className="text-2xl font-bold text-gray-800 mb-4">Spese Registrate</h3>
      <ul className="list-disc pl-5">
        {expenses.map((expense) => (
          <li key={expense.id} className="mb-2">
            <p className="font-semibold">Descrizione: {expense.description}</p>
            <p>Importo: {expense.amount} €</p>
            <p>Data: {expense.date}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Expenses;
