// src/context/FinanceContext.js
import React, { createContext, useState, useEffect } from 'react';

export const FinanceContext = createContext();

export function FinanceProvider({ children }) {
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    const storedPayments = JSON.parse(localStorage.getItem('payments')) || [];
    setPayments(storedPayments);
  }, []);

  useEffect(() => {
    localStorage.setItem('payments', JSON.stringify(payments));
  }, [payments]);

  const addPayment = (payment) => {
    setPayments([...payments, { ...payment, id: Date.now() }]);
  };

  const removePayment = (paymentId) => {
    setPayments(payments.filter((payment) => payment.id !== paymentId));
  };

  return (
    <FinanceContext.Provider value={{ payments, addPayment, removePayment }}>
      {children}
    </FinanceContext.Provider>
  );
}
