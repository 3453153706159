import React, { createContext, useState, useEffect } from 'react';

export const AlbumContext = createContext();

export const AlbumProvider = ({ children }) => {
  const [albums, setAlbums] = useState([]);

  useEffect(() => {
    const savedAlbums = localStorage.getItem('albums');
    if (savedAlbums) {
      setAlbums(JSON.parse(savedAlbums));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('albums', JSON.stringify(albums));
  }, [albums]);

  const addAlbum = (name, clientId) => {
    const newAlbum = { id: Date.now(), name, clientId };
    setAlbums([...albums, newAlbum]);
  };

  return (
    <AlbumContext.Provider value={{ albums, addAlbum }}>
      {children}
    </AlbumContext.Provider>
  );
};
