import React, { useContext, useState } from 'react';
import { ContractsContext } from '../context/ContractsContext';
import ClientSearch from '../components/ClientSearch';

function Contracts() {
  const { contracts, addContract } = useContext(ContractsContext);
  const [contractTitle, setContractTitle] = useState('');
  const [selectedClient, setSelectedClient] = useState(null);

  const handleAddContract = (e) => {
    e.preventDefault();
    if (selectedClient && contractTitle) {
      addContract({ title: contractTitle, clientId: selectedClient.id });
      setContractTitle('');
      setSelectedClient(null);
    } else {
      alert("Seleziona un cliente e inserisci il titolo del contratto.");
    }
  };

  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-3xl font-semibold text-gray-800 mb-4">Contratti</h2>

      {/* Ricerca Cliente */}
      <ClientSearch onSelect={setSelectedClient} />

      {selectedClient && (
        <div className="mt-2 mb-4">
          <h3 className="text-xl font-semibold">Cliente selezionato:</h3>
          <p>{selectedClient.name}</p>
        </div>
      )}

      {/* Form per aggiungere un contratto */}
      <form onSubmit={handleAddContract} className="mb-4">
        <div className="mb-4">
          <label className="block text-gray-700">Titolo Contratto</label>
          <input
            type="text"
            value={contractTitle}
            onChange={(e) => setContractTitle(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <button type="submit" className="px-4 py-2 bg-green-600 text-white rounded shadow">
          Aggiungi Contratto
        </button>
      </form>

      {/* Elenco contratti */}
      <h3 className="text-2xl font-bold text-gray-800 mb-4">Contratti Attivi</h3>
      <ul className="list-disc pl-5">
        {contracts.map((contract) => (
          <li key={contract.id} className="mb-2">
            <p className="font-semibold">{contract.title}</p>
            <p>Cliente: {contract.clientId ? `ID Cliente ${contract.clientId}` : 'Non associato'}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Contracts;
