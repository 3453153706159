import React from 'react';
import { DashboardProvider } from './context/DashboardContext';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ClientProvider } from './context/ClientContext';
import { AppointmentProvider } from './context/AppointmentContext';
import { AlbumProvider } from './context/AlbumContext';
import { FinanceProvider } from './context/FinanceContext';
import { EquipmentProvider } from './context/EquipmentContext';
import { ContractsProvider } from './context/ContractsContext';
import { MarketingProvider } from './context/MarketingContext';
import { SettingsProvider } from './context/SettingsContext';
import { PackageProvider } from './context/PackageContext'; // Importa il PackageProvider
import Sidebar from './components/Sidebar';
import Dashboard from './components/Dashboard';
import ClientList from './components/Clients/ClientList';
import Appointments from './components/Appointments';
import Portfolio from './components/Portfolio';
import Finance from './components/Finance';
import Equipment from './components/Equipment';
import Contracts from './components/Contracts';
import Marketing from './components/Marketing';
import Settings from './components/Settings';
import ClientProfile from './components/Clients/ClientProfile';

function App() {
  return (
    <DashboardProvider>
      <ClientProvider>
        <AppointmentProvider>
          <AlbumProvider>
            <FinanceProvider>
              <EquipmentProvider>
                <ContractsProvider>
                  <MarketingProvider>
                    <SettingsProvider>
                      <PackageProvider> {/* Avvolgi tutto in PackageProvider */}
                        <Router>
                          <div className="flex">
                            <Sidebar />
                            <div className="flex-grow p-6">
                              <Routes>
                                <Route path="/" element={<Dashboard />} />
                                <Route path="/clienti" element={<ClientList />} />
                                <Route path="/appuntamenti" element={<Appointments />} />
                                <Route path="/portfolio" element={<Portfolio />} />
                                <Route path="/finanza" element={<Finance />} />
                                <Route path="/attrezzatura" element={<Equipment />} />
                                <Route path="/contratti" element={<Contracts />} />
                                <Route path="/marketing" element={<Marketing />} />
                                <Route path="/impostazioni" element={<Settings />} />
                                <Route path="/clienti/:clientId" element={<ClientProfile />} />
                              </Routes>
                            </div>
                          </div>
                        </Router>
                      </PackageProvider>
                    </SettingsProvider>
                  </MarketingProvider>
                </ContractsProvider>
              </EquipmentProvider>
            </FinanceProvider>
          </AlbumProvider>
        </AppointmentProvider>
      </ClientProvider>
    </DashboardProvider>
  );
}

export default App;
