import React, { createContext, useState, useEffect } from 'react';

export const PackageContext = createContext();

export const PackageProvider = ({ children }) => {
  const [packages, setPackages] = useState([]);

  // Se hai una funzione per caricare i pacchetti da un database o localStorage
  useEffect(() => {
    const storedPackages = localStorage.getItem("packages");
    if (storedPackages) setPackages(JSON.parse(storedPackages));
  }, []);

  useEffect(() => {
    localStorage.setItem("packages", JSON.stringify(packages));
  }, [packages]);

  const addPackage = (newPackage) => setPackages([...packages, newPackage]);
  const removePackage = (id) => setPackages(packages.filter(pkg => pkg.id !== id));

  return (
    <PackageContext.Provider value={{ packages, addPackage, removePackage }}>
      {children}
    </PackageContext.Provider>
  );
};
