import React, { useContext } from 'react';
import { DashboardContext } from '../../context/DashboardContext';

function Notifications() {
  const { notifications } = useContext(DashboardContext);

  return (
    <div className="p-6 bg-white rounded-lg shadow-md mt-4">
      <h3 className="text-2xl font-bold text-gray-800 mb-4">Notifiche</h3>
      <ul className="list-disc pl-5">
        {notifications.map((notification) => (
          <li key={notification.id} className="mb-2">
            <p>{notification.message}</p>
            <p className="text-sm text-gray-500">{notification.date}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Notifications;
