import React from 'react';
import EquipmentList from './Equipment/EquipmentList';
import AddEquipment from './Equipment/AddEquipment';

function Equipment() {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
      <div>
        <EquipmentList />
      </div>
      <div>
        <AddEquipment />
      </div>
    </div>
  );
}

export default Equipment;
