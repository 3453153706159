import React, { useContext } from 'react';
import { EquipmentContext } from '../../context/EquipmentContext';

function EquipmentList() {
  const { inventory = [], maintenance = [], rentals = [], purchasePlans = [] } = useContext(EquipmentContext);

  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-3xl font-semibold text-gray-800 mb-4">Lista Attrezzatura</h2>

      <h3 className="text-2xl font-bold text-gray-800 mb-4">Inventario</h3>
      <ul className="list-disc pl-5">
        {inventory.map((item) => (
          <li key={item.id} className="mb-2">
            <p className="font-semibold">{item.name}</p>
            <p>Categoria: {item.category}</p>
            <p>Stato: {item.status}</p>
          </li>
        ))}
      </ul>

      <h3 className="text-2xl font-bold text-gray-800 mb-4">Manutenzione</h3>
      <ul className="list-disc pl-5">
        {maintenance.map((entry) => (
          <li key={entry.equipmentId} className="mb-2">
            <p>Attrezzatura ID: {entry.equipmentId}</p>
            <p>Stato: {entry.status}</p>
            <p>Data: {entry.date}</p>
          </li>
        ))}
      </ul>

      <h3 className="text-2xl font-bold text-gray-800 mb-4">Noleggi</h3>
      <ul className="list-disc pl-5">
        {rentals.map((rental) => (
          <li key={rental.id} className="mb-2">
            <p>Articolo: {rental.item}</p>
            <p>Noleggiatore: {rental.renter}</p>
            <p>Data: {rental.date}</p>
          </li>
        ))}
      </ul>

      <h3 className="text-2xl font-bold text-gray-800 mb-4">Piani d'Acquisto</h3>
      <ul className="list-disc pl-5">
        {purchasePlans.map((plan) => (
          <li key={plan.id} className="mb-2">
            <p>Articolo: {plan.item}</p>
            <p>Motivo: {plan.reason}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default EquipmentList;
