import React from 'react';
import Estimates from './Finance/Estimates';
import Invoices from './Finance/Invoices';
import Payments from './Finance/Payments';
import FinancialReports from './Finance/FinancialReports';
import Expenses from './Finance/Expenses';

function Finance() {
  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md">
      <Estimates />
      <Invoices />
      <Payments />
      <Expenses />
      <FinancialReports />
    </div>
  );
}

export default Finance;
