import React from 'react';
import { Link } from 'react-router-dom';

function Sidebar() {
  return (
    <div className="w-64 h-screen bg-gray-900 text-white flex flex-col p-4 shadow-lg">
      <h1 className="text-2xl font-bold mb-6 text-center">Gestionale Fotografi</h1>
      <nav className="flex flex-col gap-4">
        <Link to="/" className="hover:bg-gray-700 p-3 rounded-md transition">Dashboard</Link>
        <Link to="/clienti" className="hover:bg-gray-700 p-3 rounded-md transition">Clienti</Link>
        <Link to="/appuntamenti" className="hover:bg-gray-700 p-3 rounded-md transition">Appuntamenti</Link>
        <Link to="/portfolio" className="hover:bg-gray-700 p-3 rounded-md transition">Portfolio</Link>
        <Link to="/finanza" className="hover:bg-gray-700 p-3 rounded-md transition">Finanza</Link>
        <Link to="/attrezzatura" className="hover:bg-gray-700 p-3 rounded-md transition">Attrezzatura</Link>
        <Link to="/contratti" className="hover:bg-gray-700 p-3 rounded-md transition">Contratti</Link>
        <Link to="/marketing" className="hover:bg-gray-700 p-3 rounded-md transition">Marketing</Link>
        <Link to="/impostazioni" className="hover:bg-gray-700 p-3 rounded-md transition">Impostazioni</Link>
      </nav>
    </div>
  );
}

export default Sidebar;
