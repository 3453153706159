import React, { useContext, useState } from 'react';
import { ClientContext } from '../context/ClientContext';

function ClientSearch({ onSelect }) {
  const { clients } = useContext(ClientContext);
  const [query, setQuery] = useState('');

  // Filtro dei clienti in base alla query
  const filteredClients = clients.filter((client) =>
    client.name.toLowerCase().includes(query.toLowerCase())
  );

  return (
    <div className="mb-4">
      <input
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Cerca cliente..."
        className="p-2 border border-gray-300 rounded w-full"
      />
      {query && filteredClients.length > 0 && (
        <ul className="list-disc pl-5 mt-2 max-h-40 overflow-y-auto bg-white shadow-lg rounded">
          {filteredClients.map((client) => (
            <li
              key={client.id}
              onClick={() => {
                onSelect(client); // Seleziona il cliente
                setQuery(client.name); // Mostra il nome selezionato
              }}
              className="cursor-pointer hover:bg-gray-200 p-2 rounded"
            >
              {client.name}
            </li>
          ))}
        </ul>
      )}
      {query && filteredClients.length === 0 && (
        <p className="text-gray-500 mt-2">Nessun cliente trovato.</p>
      )}
    </div>
  );
}

export default ClientSearch;
