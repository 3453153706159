// src/components/Clients/ClientProfile.js
import React, { useContext, useState, useEffect } from 'react';
import { ClientContext } from '../../context/ClientContext';
import { AppointmentContext } from '../../context/AppointmentContext';
import { PackageContext } from '../../context/PackageContext';
import { FinanceContext } from '../../context/FinanceContext';
import { useParams } from 'react-router-dom';

function ClientProfile() {
  const { clients } = useContext(ClientContext);
  const { appointments } = useContext(AppointmentContext);
  const { packages } = useContext(PackageContext);
  const { payments, addPayment, removePayment } = useContext(FinanceContext);
  const { clientId } = useParams();
  const [client, setClient] = useState(null);
  const [clientAppointments, setClientAppointments] = useState([]);
  const [clientPayments, setClientPayments] = useState([]);
  const [paymentAmount, setPaymentAmount] = useState('');
  const [selectedPackage, setSelectedPackage] = useState('');

  useEffect(() => {
    const selectedClient = clients.find((client) => client.id === parseInt(clientId));
    setClient(selectedClient);

    const filteredAppointments = appointments.filter(
      (appointment) => appointment.clientId === parseInt(clientId)
    );
    setClientAppointments(filteredAppointments);

    const filteredPayments = payments.filter(
      (payment) => payment.clientId === parseInt(clientId)
    );
    setClientPayments(filteredPayments);
  }, [clientId, clients, appointments, payments]);

  const handleAddPayment = (e) => {
    e.preventDefault();
    if (paymentAmount && selectedPackage) {
      addPayment({
        clientId: parseInt(clientId),
        packageId: selectedPackage,
        amount: paymentAmount,
        date: new Date().toISOString().split('T')[0],
      });
      setPaymentAmount('');
      setSelectedPackage('');
    }
  };

  const handleRemovePayment = (paymentId) => {
    removePayment(paymentId);
  };

  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-3xl font-semibold text-gray-800 mb-4">Profilo Cliente</h2>

      <div className="mb-4">
        <h3 className="text-2xl font-semibold text-gray-700">Informazioni di Contatto</h3>
        <p><strong>Nome:</strong> {client?.name}</p>
        <p><strong>Email:</strong> {client?.email || 'Non specificata'}</p>
      </div>

      {/* Sezione Appuntamenti */}
      <div className="mb-4">
        <h3 className="text-2xl font-semibold text-gray-700">Appuntamenti</h3>
        <ul className="list-disc pl-5">
          {clientAppointments.map((appointment) => {
            const packageDetails = packages.find(pkg => pkg.id === appointment.packageId);
            return (
              <li key={appointment.id} className="mb-2">
                <p><strong>Data:</strong> {appointment.date}</p>
                <p><strong>Descrizione:</strong> {appointment.description}</p>
                <p><strong>Pacchetto:</strong> {packageDetails ? `${packageDetails.name} - ${packageDetails.type} (€${packageDetails.price})` : 'N/A'}</p>
              </li>
            );
          })}
        </ul>
      </div>

      {/* Sezione Pagamenti */}
      <div className="mb-4">
        <h3 className="text-2xl font-semibold text-gray-700">Pagamenti</h3>
        <ul className="list-disc pl-5">
          {clientPayments.map((payment) => {
            const packageDetails = packages.find(pkg => pkg.id === payment.packageId);
            return (
              <li key={payment.id}>
                <p><strong>Data:</strong> {payment.date}</p>
                <p><strong>Importo:</strong> €{payment.amount}</p>
                <p><strong>Pacchetto:</strong> {packageDetails ? `${packageDetails.name} - ${packageDetails.type}` : 'N/A'}</p>
                <button onClick={() => handleRemovePayment(payment.id)} className="px-2 py-1 bg-red-600 text-white rounded mt-2">
                  Elimina Pagamento
                </button>
              </li>
            );
          })}
        </ul>

        <form onSubmit={handleAddPayment} className="mt-4">
          <h4 className="text-xl font-semibold text-gray-700">Aggiungi Pagamento</h4>
          <div className="mb-2">
            <label className="block text-gray-700">Pacchetto:</label>
            <select
              value={selectedPackage}
              onChange={(e) => setSelectedPackage(e.target.value)}
              className="p-2 border border-gray-300 rounded"
              required
            >
              <option value="">Seleziona Pacchetto</option>
              {packages.map(pkg => (
                <option key={pkg.id} value={pkg.id}>{pkg.name} - {pkg.type} (€{pkg.price})</option>
              ))}
            </select>
          </div>
          <input
            type="number"
            value={paymentAmount}
            onChange={(e) => setPaymentAmount(e.target.value)}
            placeholder="Importo"
            className="p-2 border border-gray-300 rounded mr-2"
            required
          />
          <button type="submit" className="px-4 py-2 bg-green-600 text-white rounded shadow">
            Aggiungi Pagamento
          </button>
        </form>
      </div>
    </div>
  );
}

export default ClientProfile;
