import React, { useContext, useState } from 'react';
import { FinanceContext } from '../../context/FinanceContext';

function Estimates() {
  const { estimates, addEstimate } = useContext(FinanceContext);
  const [clientName, setClientName] = useState('');
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');

  const handleAddEstimate = (e) => {
    e.preventDefault();
    addEstimate({ clientName, amount, description });
    setClientName('');
    setAmount('');
    setDescription('');
  };

  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-3xl font-semibold text-gray-800 mb-4">Gestione Preventivi</h2>

      <form onSubmit={handleAddEstimate} className="mb-4">
        <div className="mb-4">
          <label className="block text-gray-700">Nome Cliente</label>
          <input
            type="text"
            value={clientName}
            onChange={(e) => setClientName(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Importo</label>
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Descrizione</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            rows="3"
            required
          />
        </div>
        <button
          type="submit"
          className="px-4 py-2 bg-blue-600 text-white rounded shadow"
        >
          Aggiungi Preventivo
        </button>
      </form>

      <h3 className="text-2xl font-bold text-gray-800 mb-4">Preventivi Esistenti</h3>
      <ul className="list-disc pl-5">
        {estimates.map((estimate) => (
          <li key={estimate.id} className="mb-2">
            <p className="font-semibold">{estimate.clientName}</p>
            <p>Importo: {estimate.amount} €</p>
            <p>Descrizione: {estimate.description}</p>
            <p>Data: {estimate.date}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Estimates;
