import React, { useContext } from 'react';
import { FinanceContext } from '../../context/FinanceContext';

function FinancialReports() {
  const { estimates, invoices, payments } = useContext(FinanceContext);

  // Calcola il totale dei preventivi
  const totalEstimates = estimates.reduce((total, estimate) => total + parseFloat(estimate.amount || 0), 0);

  // Calcola il totale delle fatture
  const totalInvoices = invoices.reduce((total, invoice) => total + parseFloat(invoice.amount || 0), 0);

  // Calcola il totale dei pagamenti
  const totalPayments = payments.reduce((total, payment) => total + parseFloat(payment.amount || 0), 0);

  // Calcola il saldo finale (totale delle fatture - totale dei pagamenti)
  const balance = totalInvoices - totalPayments;

  return (
    <div className="p-6 bg-white rounded-lg shadow-md mt-4">
      <h2 className="text-3xl font-semibold text-gray-800 mb-4">Report Finanziari</h2>
      <div className="text-lg">
        <p><strong>Totale Preventivi:</strong> {totalEstimates.toFixed(2)} €</p>
        <p><strong>Totale Fatture:</strong> {totalInvoices.toFixed(2)} €</p>
        <p><strong>Totale Pagamenti Ricevuti:</strong> {totalPayments.toFixed(2)} €</p>
        <p className="mt-2 text-xl font-bold">
          <strong>Saldo Finale:</strong> {balance.toFixed(2)} €
        </p>
      </div>
    </div>
  );
}

export default FinancialReports;
