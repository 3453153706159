import React, { useContext, useState } from 'react';
import { FinanceContext } from '../../context/FinanceContext';

function Payments() {
  const { payments, addPayment } = useContext(FinanceContext);
  const [clientName, setClientName] = useState('');
  const [amount, setAmount] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');

  const handleAddPayment = (e) => {
    e.preventDefault();
    addPayment({ clientName, amount, paymentMethod });
    setClientName('');
    setAmount('');
    setPaymentMethod('');
  };

  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-3xl font-semibold text-gray-800 mb-4">Gestione Pagamenti</h2>

      <form onSubmit={handleAddPayment} className="mb-4">
        <div className="mb-4">
          <label className="block text-gray-700">Nome Cliente</label>
          <input
            type="text"
            value={clientName}
            onChange={(e) => setClientName(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Importo</label>
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Metodo di Pagamento</label>
          <select
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          >
            <option value="">Seleziona metodo</option>
            <option value="Carta di Credito">Carta di Credito</option>
            <option value="Bonifico">Bonifico</option>
            <option value="Contanti">Contanti</option>
          </select>
        </div>
        <button
          type="submit"
          className="px-4 py-2 bg-green-600 text-white rounded shadow"
        >
          Aggiungi Pagamento
        </button>
      </form>

      <h3 className="text-2xl font-bold text-gray-800 mb-4">Pagamenti Registrati</h3>
      <ul className="list-disc pl-5">
        {payments.map((payment) => (
          <li key={payment.id} className="mb-2">
            <p className="font-semibold">{payment.clientName}</p>
            <p>Importo: {payment.amount} €</p>
            <p>Metodo di Pagamento: {payment.paymentMethod}</p>
            <p>Data: {payment.date}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Payments;
