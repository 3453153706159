import React, { useContext, useState } from 'react';
import { DashboardContext } from '../../context/DashboardContext';

function ToDoList() {
  const { tasks, addTask, toggleTaskCompletion } = useContext(DashboardContext);
  const [taskText, setTaskText] = useState('');

  const handleAddTask = (e) => {
    e.preventDefault();
    addTask({ text: taskText });
    setTaskText('');
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-md mt-4">
      <h3 className="text-2xl font-bold text-gray-800 mb-4">To-Do List</h3>

      <form onSubmit={handleAddTask} className="mb-4">
        <input
          type="text"
          value={taskText}
          onChange={(e) => setTaskText(e.target.value)}
          placeholder="Nuova Attività"
          className="p-2 border border-gray-300 rounded mr-2"
          required
        />
        <button type="submit" className="px-4 py-2 bg-green-600 text-white rounded shadow">
          Aggiungi
        </button>
      </form>

      <ul className="list-disc pl-5">
        {tasks.map((task) => (
          <li key={task.id} className="mb-2">
            <input
              type="checkbox"
              checked={task.completed}
              onChange={() => toggleTaskCompletion(task.id)}
              className="mr-2"
            />
            <span className={task.completed ? 'line-through' : ''}>{task.text}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ToDoList;
