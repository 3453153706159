import React, { createContext, useState } from 'react';

export const ContractsContext = createContext();

export const ContractsProvider = ({ children }) => {
  const [contracts, setContracts] = useState([]);
  const [releases, setReleases] = useState([]);
  const [legalDocuments, setLegalDocuments] = useState([]);

  const addContract = (contract) => {
    setContracts((prevContracts) => [
      ...prevContracts,
      { ...contract, id: prevContracts.length + 1, date: new Date().toLocaleDateString() }
    ]);
  };

  const addRelease = (release) => {
    setReleases((prevReleases) => [
      ...prevReleases,
      { ...release, id: prevReleases.length + 1, date: new Date().toLocaleDateString() }
    ]);
  };

  const addLegalDocument = (document) => {
    setLegalDocuments((prevDocuments) => [
      ...prevDocuments,
      { ...document, id: prevDocuments.length + 1, date: new Date().toLocaleDateString() }
    ]);
  };

  return (
    <ContractsContext.Provider value={{ contracts, addContract, releases, addRelease, legalDocuments, addLegalDocument }}>
      {children}
    </ContractsContext.Provider>
  );
};
