// src/context/AppointmentContext.js
import React, { createContext, useState } from 'react';

export const AppointmentContext = createContext();

export const AppointmentProvider = ({ children }) => {
  const [appointments, setAppointments] = useState([]);

  // Funzione per aggiungere un appuntamento
  const addAppointment = (appointment) => {
    setAppointments([...appointments, { ...appointment, id: Date.now() }]);
  };

  // Funzione per rimuovere un appuntamento
  const removeAppointment = (appointmentId) => {
    setAppointments(appointments.filter(appointment => appointment.id !== appointmentId));
  };

  return (
    <AppointmentContext.Provider value={{ appointments, addAppointment, removeAppointment }}>
      {children}
    </AppointmentContext.Provider>
  );
};
