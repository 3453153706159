import React, { useState, useContext } from 'react';
import { EquipmentContext } from '../../context/EquipmentContext';

function AddEquipment() {
  const [name, setName] = useState('');
  const [status, setStatus] = useState('Disponibile');
  const [lastMaintenance, setLastMaintenance] = useState('');
  const { addEquipment } = useContext(EquipmentContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    addEquipment({ name, status, lastMaintenance });
    setName('');
    setStatus('Disponibile');
    setLastMaintenance('');
  };

  return (
    <div className="my-4">
      <h2 className="text-xl font-bold mb-4">Aggiungi Nuova Attrezzatura</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700">Nome</label>
          <input
  type="text"
  className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-500 transition-all duration-300"
/>

        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Stato</label>
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          >
            <option value="Disponibile">Disponibile</option>
            <option value="In uso">In uso</option>
            <option value="In manutenzione">In manutenzione</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Ultima Manutenzione</label>
          <input
  type="text"
  className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-500 transition-all duration-300"
/>

        </div>
        <button
  type="submit"
  className="px-6 py-2 bg-blue-600 text-white font-semibold rounded-lg shadow hover:bg-blue-700 transition-all duration-300"
>
  Aggiungi Cliente
</button>

      </form>
    </div>
  );
}

export default AddEquipment;
