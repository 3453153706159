import React from 'react';
import Overview from './Dashboard/Overview';
import Notifications from './Dashboard/Notifications';
import ToDoList from './Dashboard/ToDoList';

function Dashboard() {
  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md">
      <Overview />
      <Notifications />
      <ToDoList />
    </div>
  );
}

export default Dashboard;
