// src/components/Settings/Packages/PackageManager.js
import React, { useState, useEffect } from 'react';

function PackageManager() {
  const [packages, setPackages] = useState([]);
  const [packageName, setPackageName] = useState('');
  const [packageType, setPackageType] = useState('Basic');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');

  // Carica i pacchetti salvati dal localStorage al primo render
  useEffect(() => {
    const savedPackages = localStorage.getItem('packages');
    if (savedPackages) {
      setPackages(JSON.parse(savedPackages));
    }
  }, []);

  // Salva i pacchetti nel localStorage ogni volta che il valore di `packages` cambia
  useEffect(() => {
    localStorage.setItem('packages', JSON.stringify(packages));
  }, [packages]);

  const handleAddPackage = (e) => {
    e.preventDefault();
    const newPackage = {
      id: packages.length + 1,
      name: packageName,
      type: packageType,
      price: parseFloat(price),
      description,
    };
    setPackages([...packages, newPackage]);
    setPackageName('');
    setPackageType('Basic');
    setPrice('');
    setDescription('');
  };

  return (
    <div className="p-6 bg-white rounded shadow-md">
      <h2 className="text-2xl font-bold mb-4">Gestione dei Pacchetti</h2>
      
      <form onSubmit={handleAddPackage} className="mb-6">
        <div className="mb-4">
          <label className="block text-gray-700">Nome Pacchetto</label>
          <input
            type="text"
            value={packageName}
            onChange={(e) => setPackageName(e.target.value)}
            className="w-full p-2 border rounded"
            placeholder="E.g., Maternity"
            required
          />
        </div>
        
        <div className="mb-4">
          <label className="block text-gray-700">Tipo</label>
          <select
            value={packageType}
            onChange={(e) => setPackageType(e.target.value)}
            className="w-full p-2 border rounded"
          >
            <option value="Basic">Basic</option>
            <option value="Premium">Premium</option>
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Prezzo (€)</label>
          <input
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            className="w-full p-2 border rounded"
            placeholder="E.g., 329.99"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Descrizione</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full p-2 border rounded"
            placeholder="Descrivi cosa include il pacchetto"
            required
          />
        </div>

        <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded shadow">
          Aggiungi Pacchetto
        </button>
      </form>

      <h3 className="text-xl font-semibold mb-2">Pacchetti Disponibili</h3>
      {packages.length === 0 ? (
        <p>Nessun pacchetto creato.</p>
      ) : (
        <ul className="list-disc pl-5">
          {packages.map((pkg) => (
            <li key={pkg.id} className="mb-2">
              <p className="font-bold">{pkg.name} - {pkg.type}</p>
              <p>Prezzo: €{pkg.price.toFixed(2)}</p>
              <p>Descrizione: {pkg.description}</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default PackageManager;
