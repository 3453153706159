import React, { useContext, useState } from 'react';
import { FinanceContext } from '../../context/FinanceContext';

function Invoices() {
  const { invoices, addInvoice } = useContext(FinanceContext);
  const [clientName, setClientName] = useState('');
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');

  const handleAddInvoice = (e) => {
    e.preventDefault();
    addInvoice({ clientName, amount, description });
    setClientName('');
    setAmount('');
    setDescription('');
  };

  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-3xl font-semibold text-gray-800 mb-4">Gestione Fatture</h2>

      <form onSubmit={handleAddInvoice} className="mb-4">
        <div className="mb-4">
          <label className="block text-gray-700">Nome Cliente</label>
          <input
            type="text"
            value={clientName}
            onChange={(e) => setClientName(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Importo</label>
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Descrizione</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            rows="3"
            required
          />
        </div>
        <button
          type="submit"
          className="px-4 py-2 bg-blue-600 text-white rounded shadow"
        >
          Aggiungi Fattura
        </button>
      </form>

      <h3 className="text-2xl font-bold text-gray-800 mb-4">Fatture Esistenti</h3>
      <ul className="list-disc pl-5">
        {invoices.map((invoice) => (
          <li key={invoice.id} className="mb-2">
            <p className="font-semibold">{invoice.clientName}</p>
            <p>Importo: {invoice.amount} €</p>
            <p>Descrizione: {invoice.description}</p>
            <p>Data: {invoice.date}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Invoices;
