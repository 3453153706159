import React, { createContext, useState } from 'react';

export const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
  const [studioProfile, setStudioProfile] = useState({ name: '', address: '', phone: '' });
  const [preferences, setPreferences] = useState({ theme: 'light', notifications: true });

  const updateStudioProfile = (profile) => {
    setStudioProfile(profile);
  };


  const updatePreferences = (newPreferences) => {
    setPreferences((prevPreferences) => ({ ...prevPreferences, ...newPreferences }));
  };

  return (
    <SettingsContext.Provider value={{ studioProfile, updateStudioProfile, preferences, updatePreferences }}>
      {children}
    </SettingsContext.Provider>
  );
};
