import React, { createContext, useState } from 'react';

export const EquipmentContext = createContext();

export const EquipmentProvider = ({ children }) => {
  const [inventory, setInventory] = useState([]);
  const [maintenance, setMaintenance] = useState([]);
  const [rentals, setRentals] = useState([]);
  const [purchasePlans, setPurchasePlans] = useState([]);

  const addEquipment = (equipment) => {
    setInventory((prevInventory) => [
      ...prevInventory,
      { ...equipment, id: prevInventory.length + 1 }
    ]);
  };

  const addMaintenance = (equipmentId, status) => {
    setMaintenance((prevMaintenance) => [
      ...prevMaintenance,
      { equipmentId, status, date: new Date().toLocaleDateString() }
    ]);
  };

  const addRental = (rental) => {
    setRentals((prevRentals) => [
      ...prevRentals,
      { ...rental, id: prevRentals.length + 1, date: new Date().toLocaleDateString() }
    ]);
  };

  const addPurchasePlan = (plan) => {
    setPurchasePlans((prevPlans) => [
      ...prevPlans,
      { ...plan, id: prevPlans.length + 1 }
    ]);
  };

  return (
    <EquipmentContext.Provider
      value={{
        inventory,
        addEquipment,
        maintenance,
        addMaintenance,
        rentals,
        addRental,
        purchasePlans,
        addPurchasePlan,
      }}
    >
      {children}
    </EquipmentContext.Provider>
  );
};
