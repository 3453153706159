import React, { createContext, useState } from 'react';

export const MarketingContext = createContext();

export const MarketingProvider = ({ children }) => {
  const [promotions, setPromotions] = useState([]);
  const [reviews, setReviews] = useState([]);

  const addPromotion = (promotion) => {
    setPromotions((prevPromotions) => [
      ...prevPromotions,
      { ...promotion, id: prevPromotions.length + 1, date: new Date().toLocaleDateString() }
    ]);
  };

  const addReview = (review) => {
    setReviews((prevReviews) => [
      ...prevReviews,
      { ...review, id: prevReviews.length + 1, date: new Date().toLocaleDateString() }
    ]);
  };

  return (
    <MarketingContext.Provider value={{ promotions, addPromotion, reviews, addReview }}>
      {children}
    </MarketingContext.Provider>
  );
};
