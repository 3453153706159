import React, { useState } from 'react';
import PackageManager from './Packages/PackageManager'; // Assicurati che il percorso sia corretto

function StudioProfile() {
  const [showPackageManager, setShowPackageManager] = useState(false);

  const handleTogglePackageManager = () => {
    setShowPackageManager(!showPackageManager);
  };

  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-3xl font-semibold text-gray-800 mb-4">Profilo Studio</h2>

      {/* Bottone per mostrare/nascondere Configura Pacchetti */}
      <button onClick={handleTogglePackageManager} className="px-4 py-2 bg-blue-600 text-white rounded shadow">
        Configura Pacchetti
      </button>

      {/* Mostra il componente PackageManager se showPackageManager è true */}
      {showPackageManager && <PackageManager />}
    </div>
  );
}

export default StudioProfile;
