import React, { useContext, useState } from 'react';
import { MarketingContext } from '../context/MarketingContext';
import ClientSearch from '../components/ClientSearch';

function Marketing() {
  const { promotions, addPromotion } = useContext(MarketingContext);
  const [promotionTitle, setPromotionTitle] = useState('');
  const [promotionDescription, setPromotionDescription] = useState('');
  const [selectedClient, setSelectedClient] = useState(null);

  const handleAddPromotion = (e) => {
    e.preventDefault();
    if (selectedClient && promotionTitle) {
      addPromotion({ title: promotionTitle, description: promotionDescription, clientId: selectedClient.id });
      setPromotionTitle('');
      setPromotionDescription('');
      setSelectedClient(null);
    } else {
      alert("Seleziona un cliente e inserisci i dettagli della promozione.");
    }
  };

  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-3xl font-semibold text-gray-800 mb-4">Marketing</h2>

      {/* Ricerca Cliente */}
      <ClientSearch onSelect={setSelectedClient} />

      {selectedClient && (
        <div className="mt-2 mb-4">
          <h3 className="text-xl font-semibold">Cliente selezionato:</h3>
          <p>{selectedClient.name}</p>
        </div>
      )}

      {/* Form per aggiungere una promozione */}
      <form onSubmit={handleAddPromotion} className="mb-4">
        <div className="mb-4">
          <label className="block text-gray-700">Titolo Promozione</label>
          <input
            type="text"
            value={promotionTitle}
            onChange={(e) => setPromotionTitle(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Descrizione Promozione</label>
          <textarea
            value={promotionDescription}
            onChange={(e) => setPromotionDescription(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            rows="4"
          />
        </div>
        <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded shadow">
          Aggiungi Promozione
        </button>
      </form>

      {/* Elenco promozioni */}
      <h3 className="text-2xl font-bold text-gray-800 mb-4">Promozioni Attive</h3>
      <ul className="list-disc pl-5">
        {promotions.map((promotion) => (
          <li key={promotion.id} className="mb-2">
            <p className="font-semibold">{promotion.title}</p>
            <p>Descrizione: {promotion.description}</p>
            <p>Cliente: {promotion.clientId ? `ID Cliente ${promotion.clientId}` : 'Non associato'}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Marketing;
